"use client";

import { useRouter } from "next/router";
import type { PropsWithChildren } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import type { DeepReadonly } from "ts-essentials";

import { CommonAppContext } from "./CommonAppContext";
import type {
  GraphqlCollection,
  GraphqlOperation,
  GraphQLOperationStore,
} from "./types";
import { CollectionsAPI } from "./CollectionsAPI";

const localCache: Set<string> = new Set();

async function queryAPIForDocuments(
  collectionId: string,
  parentId: string,
  collectionsAPI: CollectionsAPI,
) {
  if (localCache.has(parentId)) {
    return undefined;
  }

  return collectionsAPI.getDocumentsByParent({ collectionId, parentId });
}

export interface GraphiqlState {
  readonly activeCollection?: GraphqlCollection;
  readonly allCollections?: GraphqlCollection[];
  readonly activeCollectionId: string;
  readonly activeOperation?: GraphqlOperation;
  createCollection: (inputs: {
    collectionId: string;
    collectionName: string;
  }) => Promise<void>;

  updateChildInCollection: (inputs: {
    collectionId: string;
    parentId: string;
    childId: string;
    body: GraphqlOperation;
  }) => Promise<void>;
}

export const GraphiqlContext = createContext<GraphiqlState>({
  activeCollection: undefined,
  activeCollectionId: "",
  activeOperation: undefined,
  createCollection: () => Promise.resolve(),
  updateChildInCollection: () => Promise.resolve(),
});

export const GraphiqlContextProvider = ({
  children,
}: DeepReadonly<PropsWithChildren<unknown>>): JSX.Element => {
  // State Init
  const [allCollections, setAllCollections] = useState<
    GraphqlCollection[] | undefined
  >(undefined);
  const [activeCollectionId, setActiveCollectionId] = useState<string>("");
  const [activeOperation, setActiveOperation] = useState<
    GraphqlOperation | undefined
  >(undefined);
  const router = useRouter();
  const { activeOrganization } = useContext(CommonAppContext);

  const collectionsAPI = useMemo(
    () => new CollectionsAPI(activeOrganization),
    [activeOrganization],
  );

  const createCollection: GraphiqlState["createCollection"] = useCallback(
    async (inputs) => {
      const collection = await collectionsAPI.createCollection(inputs);
      setAllCollections([...(allCollections || []), collection]);
      setActiveCollectionId(collection.id);
    },
    [collectionsAPI, allCollections],
  );

  const updateChildInCollection: GraphiqlState["updateChildInCollection"] =
    useCallback(
      async (inputs) => {
        const child = await collectionsAPI.updateChildInCollection(inputs);

        if (allCollections) {
          const updatedCollections = Array.from(allCollections);
          const activeCollectionIndex = updatedCollections.findIndex(
            (object) => object.id === inputs.collectionId,
          );

          // eslint-disable-next-line fp/no-mutation
          updatedCollections[activeCollectionIndex] = {
            ...updatedCollections[activeCollectionIndex],
            children: {
              ...updatedCollections[activeCollectionIndex].children,
              [child.id]: { ...child },
            },
          };

          setAllCollections(updatedCollections);
        }
      },
      [collectionsAPI, allCollections],
    );

  // Recursive Router Logic
  useEffect(() => {
    async function parseSlug() {
      const { slug } = router.query;
      if (slug !== undefined && allCollections) {
        const updateAllCollections = Array.from(allCollections);

        const slugArray = typeof slug === "string" ? Array(slug) : slug;
        for (const slugSection of slugArray) {
          const collectionIndex = updateAllCollections.findIndex(
            (object) => object.id === slugSection,
          );
          const collectionObject = updateAllCollections[collectionIndex];
          if (collectionIndex === -1) {
            // If not found we are looking for a lower level obj
            const currentCollectionIndex = updateAllCollections.findIndex(
              (subCollection) => subCollection.id === activeCollectionId,
            );
            const operationObject =
              updateAllCollections[currentCollectionIndex]?.children?.[
                slugSection
              ];
            if (operationObject) {
              // Folder Or Operation
              if (operationObject.documentType === "folder") {
                const childObjects = await queryAPIForDocuments(
                  activeCollectionId,
                  slugSection,
                  collectionsAPI,
                );
                if (childObjects) {
                  const childrenObject: GraphQLOperationStore = {};
                  childObjects.forEach((operation) => {
                    // eslint-disable-next-line fp/no-mutation
                    childrenObject[operation.id] = operation;
                  });
                  const updatedCollection: GraphqlCollection = {
                    ...updateAllCollections[currentCollectionIndex],

                    children: {
                      ...updateAllCollections[currentCollectionIndex]?.children,
                      ...childrenObject,
                    },
                  };
                  // eslint-disable-next-line fp/no-mutation
                  updateAllCollections[currentCollectionIndex] =
                    updatedCollection;
                }
              } else {
                setActiveOperation(operationObject as GraphqlOperation);
              }
            }
          } else {
            // Top Level Collection - Only run once
            if (!collectionObject.children) {
              const childObjects = await queryAPIForDocuments(
                slugSection,
                // get first level children
                slugSection,
                collectionsAPI,
              );
              if (childObjects) {
                const childrenObject: GraphQLOperationStore = {};
                childObjects.forEach((operation) => {
                  // eslint-disable-next-line fp/no-mutation
                  childrenObject[operation.id] = operation;
                });

                // eslint-disable-next-line fp/no-mutation
                updateAllCollections[collectionIndex] = {
                  ...updateAllCollections[collectionIndex],
                  children: childrenObject,
                };
                setActiveCollectionId(collectionObject.id);
              }
            }
          }
        }
        setAllCollections(updateAllCollections);
      }
    }
    if (
      allCollections?.length !== 0 &&
      router.asPath.includes("/docs/explorer")
    ) {
      void parseSlug();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, activeCollectionId]);

  // Get list of all top level collections for dropdown
  const getCollections = useCallback(async () => {
    if (
      !allCollections &&
      router.asPath.includes("/docs/explorer") &&
      activeOrganization?.jwt !== undefined
    ) {
      const collectionArray = await collectionsAPI.getCollections();
      if (collectionArray && collectionArray.length > 0) {
        setAllCollections(collectionArray);
        if (activeCollectionId === "") {
          setActiveCollectionId(collectionArray[0].id);
        }
        if (router.asPath === "/docs/explorer/default") {
          await router.push(
            {
              pathname: `/docs/explorer/${collectionArray[0].id}`,
            },
            undefined,
            { scroll: true, shallow: true },
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrganization, allCollections, router.asPath]);

  // Run once on startup
  useEffect(() => {
    if (
      !allCollections &&
      router.asPath.includes("/docs/explorer") &&
      activeOrganization?.jwt !== undefined
    ) {
      void getCollections();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrganization, router.asPath]);

  // Run once on startup
  useEffect(() => {
    if (router.asPath.includes("/docs/explorer")) {
      const { slug } = router.query;
      if (
        slug?.[0] &&
        slug[0] !== "default" &&
        slug[0] !== activeCollectionId &&
        allCollections?.length
      ) {
        setActiveCollectionId(slug[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, allCollections]);

  // Exporting
  const activeCollection = useMemo(
    () => allCollections?.find((x) => x.id === activeCollectionId),
    [activeCollectionId, allCollections],
  );
  const contextValues = useMemo(
    () => ({
      activeCollection,
      activeCollectionId,
      allCollections,
      activeOperation,
      createCollection,
      updateChildInCollection,
    }),
    [
      activeCollection,
      activeCollectionId,
      allCollections,
      activeOperation,
      createCollection,
      updateChildInCollection,
    ],
  );

  return (
    <GraphiqlContext.Provider value={contextValues}>
      {children}
    </GraphiqlContext.Provider>
  );
};
