"use client";

import Link from "next/link";
import React, { useContext } from "react";
import classNames from "classnames";
import { useRouter } from "next/router";
import { CommonAppContext } from "@bay1/data";
import { UserRole } from "@bay1/sdk/generated/graphql";

import { checkUserAccess } from "../helpers";

import { TestOnly } from "./TestOnly";
import { OpsOnly } from "./OpsOnly";
import { ConditionalWrapper } from "./ConditionalWrapper";

interface SidebarLinkNavItem {
  text: string;
  href?: string;
  liveOnly?: boolean;
  isActiveLink?: boolean;
  userHasAccess: boolean;
  testOnly: boolean;
  opsRoles?: string | string[];
}

interface MainSidebarLinkNavItem extends SidebarLinkNavItem {
  icon: React.ReactElement<SVGElement>;
  iconActive: React.ReactElement<SVGElement>;
}

interface SecondarySidebarLinkNavItem extends SidebarLinkNavItem {
  icon: React.ReactElement<SVGElement>;
  iconActive: React.ReactElement<SVGElement>;
}

export const SidebarLink = (
  props: Readonly<MainSidebarLinkNavItem | SecondarySidebarLinkNavItem>,
): JSX.Element => {
  const {
    text,
    href,
    liveOnly,
    isActiveLink = false,
    userHasAccess = false,
    icon,
    iconActive,
    testOnly,
    opsRoles,
  } = props;

  const pageHref = userHasAccess ? href : undefined;
  const disabled = !(pageHref ?? "");
  const isSmall = "smallIcon" in props;

  const anchor = (
    <a
      className={classNames(
        "text-md rounded-rounded group relative flex items-center px-2 py-1",
        {
          "text-black": !disabled,
          "cursor-not-allowed text-gray-400 ": disabled,
          "bg-ash rounded-full opacity-100": isActiveLink,
          "hover:bg-ash": !isActiveLink && !disabled,
          "py-1.5 text-xs": isSmall,
        },
      )}
      data-testid="sidebar::anchor"
      key={text}
    >
      <div
        className={classNames({
          "absolute mr-3 h-5 w-5 text-black": !isSmall,
          "opacity-30": !isActiveLink && disabled,
          "opacity-0": isActiveLink,
        })}
      >
        {icon}
      </div>
      <div
        className={classNames({
          "mr-3 h-5 w-5 text-black": !isSmall,
          "opacity-0": !isActiveLink,
          "opacity-100": isActiveLink,
        })}
      >
        {iconActive}
      </div>

      <span className="truncate">{text}</span>

      {(liveOnly ?? false) && (
        <span className="bg-ash ml-2 inline-flex items-center rounded px-2 py-0.5 text-xs font-medium text-black">
          Live Only
        </span>
      )}
      {opsRoles !== undefined && (
        <OpsOnly roles={opsRoles}>
          <img alt="" className="ml-2" src="/img/ops-icon-inverse.svg" />
        </OpsOnly>
      )}
    </a>
  );

  if (opsRoles !== undefined) {
    return (
      <OpsOnly roles={opsRoles}>
        {pageHref === undefined ? (
          anchor
        ) : (
          <Link href={pageHref} key={text} legacyBehavior>
            {anchor}
          </Link>
        )}
      </OpsOnly>
    );
  } else {
    return (
      <ConditionalWrapper condition={testOnly} wrapper={TestOnly}>
        {pageHref === undefined ? (
          anchor
        ) : (
          <Link href={pageHref} key={text} legacyBehavior>
            {anchor}
          </Link>
        )}
      </ConditionalWrapper>
    );
  }
};

const SidebarNav = ({
  children,
  isMobile,
}: Readonly<React.PropsWithChildren<{ isMobile: boolean }>>): JSX.Element => {
  const { pathname, query } = useRouter();
  const { user } = useContext(CommonAppContext);

  const { id: organizationIds } = query;
  const [organizationId] = Array(organizationIds).flat();

  const sidebarMainLinks: MainSidebarLinkNavItem[] = [
    {
      text: "Home",
      href: `/organizations/${organizationId}/home`,
      isActiveLink: pathname === "/organizations/[id]/home",
      userHasAccess: true,
      icon: <img alt="" src="/img/home-icon.svg" />,
      iconActive: <img alt="" src="/img/home-active-icon.svg" />,
      testOnly: false,
    },
    {
      text: "Account Holders",
      href: `/organizations/${organizationId}/account-holders`,

      isActiveLink:
        pathname === "/organizations/[id]/account-holders" ||
        pathname === "/organizations/[id]/business-account-holders",

      userHasAccess: checkUserAccess(
        [
          UserRole.ADMIN,
          UserRole.DEVELOPER,
          UserRole.FINANCE,
          UserRole.SUPPORT,
          UserRole.USER,
          UserRole.UNSPECIFIED,
        ],
        user?.roles,
      ),

      icon: <img alt="" src="/img/account-holder-icon.svg" />,
      iconActive: <img alt="" src="/img/account-holder-icon-active.svg" />,
      testOnly: false,
    },
  ];

  const sidebarSecondaryLinks: SecondarySidebarLinkNavItem[] = [
    {
      text: "Auth Controls",
      href: `/organizations/${organizationId}/authorization-controls/spend-rules`,
      isActiveLink:
        pathname === "/organizations/[id]/authorization-controls/spend-rules" ||
        pathname ===
          "/organizations/[id]/authorization-controls/velocity-controls",

      userHasAccess: checkUserAccess(
        [UserRole.ADMIN, UserRole.DEVELOPER, UserRole.FINANCE],
        user?.roles,
      ),

      icon: <img alt="" src="/img/spend-rule-inactive.svg" />,
      iconActive: <img alt="" src="/img/spend-rule-table.svg" />,
      testOnly: false,
    },
    {
      text: "Transfer Controls",
      href: `/organizations/${organizationId}/transfer-controls`,
      isActiveLink:
        pathname === "/organizations/[id]/transfer-controls" ||
        pathname === "/organizations/[id]/transfer-controls/velocity-controls",

      userHasAccess: checkUserAccess(
        [UserRole.ADMIN, UserRole.DEVELOPER, UserRole.FINANCE],
        user?.roles,
      ),

      icon: <img alt="" src="/img/transfer-controls-active.svg" />,
      iconActive: <img alt="" src="/img/transfer-controls-active.svg" />,
      testOnly: false,
      opsRoles: ["risk_mgmt_manager", "risk_mgmt_support"],
    },
    {
      text: "Developers",
      href: `/organizations/${organizationId}/developer`,
      isActiveLink: pathname === "/organizations/[id]/developer",

      userHasAccess: checkUserAccess(
        [UserRole.ADMIN, UserRole.DEVELOPER],
        user?.roles,
      ),

      icon: <img alt="" src="/img/api-keys-icon.svg" />,
      iconActive: <img alt="" src="/img/api-keys-active.svg" />,
      testOnly: false,
    },
    {
      text: "Organization",
      href: `/organizations/${organizationId}/settings`,

      isActiveLink:
        pathname === "/organizations/[id]/settings" ||
        pathname === "/organizations/[id]/team",

      userHasAccess: checkUserAccess([UserRole.ADMIN], user?.roles),
      icon: <img alt="" src="/img/settings-icon.svg" />,
      iconActive: <img alt="" src="/img/settings-icon-active.svg" />,
      testOnly: false,
    },
  ];

  if (isMobile) {
    return (
      <div className="flex w-full grow flex-col overflow-y-auto pb-4 lg:w-64">
        <div className="flex grow flex-col pt-0 lg:pt-5">
          <nav
            aria-label="Sidebar"
            className="mt-0 flex-1 space-y-8 px-0 md:px-2 lg:mt-4"
          >
            <div className="space-y-1.5" role="group">
              {sidebarMainLinks.map((link) => (
                <SidebarLink key={link.text} {...link} />
              ))}
            </div>
            {children}
            <div className="space-y-1.5">
              {sidebarSecondaryLinks.map((link) => (
                <SidebarLink key={link.text} {...link} />
              ))}
            </div>
          </nav>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed z-20 hidden h-full lg:flex lg:shrink-0">
      <div className="relative flex w-64 flex-col">
        <div className="flex w-full grow flex-col overflow-y-auto pb-4 lg:w-64">
          <div className="flex grow flex-col pt-0 lg:pt-2">
            <nav
              aria-label="Sidebar"
              className="relative mt-0 flex-1 pb-10 lg:mt-4"
            >
              <div className="space-y-0.5 px-0 pb-5 md:px-2" role="group">
                {sidebarMainLinks.map((link) => (
                  <SidebarLink key={link.text} {...link} />
                ))}
              </div>
              {children}
              <div className="space-y-0.5 px-0 pb-16 pt-2 md:px-2 md:pt-6">
                {sidebarSecondaryLinks.map((link) => (
                  <SidebarLink key={link.text} {...link} />
                ))}
              </div>
            </nav>
          </div>
        </div>
        <div className="from-bone absolute left-0 right-2 top-0 h-8 bg-gradient-to-b to-transparent"></div>
      </div>
    </div>
  );
};

export default SidebarNav;
